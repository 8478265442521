<template>
    <div class="contenedor-orden-dia">
        <div class="orden-titulo">
            <h1>Sesión Ordinaria 42º</h1>
        </div>
        <div class="logos"></div>
        <div class="orden-body">
            <h3>CUADRAGÉSIMA SEGUNDA SESIÓN ORDINARIA DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERADICAR LA VIOLENCIA CONTRA LAS MUJERES</h3>
            <p>A TRAVES DE LA PLATAFORMA VIRTUAL ZOOM, CUERNAVACA, MORELOS</p>
            <p>INICIO: <b>11:00 HORAS</b></p>
            <h3>ORDEN DEL DÍA</h3>
            <ol>
                <li>BIENVENIDA A LAS Y LOS INTEGRANTES E INVITADOS DEL SISTEMA, DE LA SOCIEDAD CIVIL Y/O ACADEMIA, ASÍ COMO AUTORIDADES QUE ACOMPAÑAN, POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO Y PRESIDENTE DEL SEPASE <b>(5 MINS)</b> </li>
                <li>PASE DE LISTA Y DECLARACIÓN DE QUÓRUM LEGAL, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b></li>
                <li>LECTURA Y EN SU CASO APROBACIÓN DEL ORDEN DEL DÍA, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1Zv7BXrBQoFRN_SzQifhZ1617v3K3xhCg/view?usp=sharing" target="_blink">- Mostrar documento</a></li>
                <li>DISPENSA, APROBACIÓN Y FIRMA DEL ACTA CORRESPONDIENTE A LA SESIÓN ANTERIOR (CUADRAGÉSIMA PRIMERA), POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1cFY7TowaAakTz-M1kZXjk70acfrIFAZS/view?usp=sharing" target="_blank"> - Mostrar documento (41º sesión ordinaria)</a> </li>
                <li>SEGUIMIENTO DE LOS ACUERDOS EMANADOS DE LAS SESIONES ORDINARIAS DEL SISTEMA ESTATAL PARA PREVENIR, ATENDER, SANCIONAR Y ERRADICAR LA VIOLENCIA CONTRA LAS MUJERES, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (5 MINS)</b> <a href="https://drive.google.com/file/d/1bQ4pPVC1u0cR45VfvhAxsvX9SGJGgjj1/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                <li>PRESENTACIÓN Y EN SU CASO APROBACIÓN DE LA METODOLOGÍA PARA LA PRESENTACIÓN DEL MECANISMO DE SEGUIMIENTO Y EVALUACIÓN DEL SEPASE, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b> (5 MINS)</b><a href="https://drive.google.com/file/d/1SyiBtHgzj4_1FKKuZVgzExDpdivsO7t5/view?usp=sharing" target="_blank">- Acuerdo Metodologia</a> <a href="https://drive.google.com/file/d/1b4e8ut1pFjzQn63-SJCVogRbXPVUh8pz/view?usp=sharing" target="_blank"><div>Documento Propuesta MIR</div></a></li>
                <li>PRESENTACIÓN DE LA CONVOCATORIA PARA QUE ORGANIZACIONES DE LA SOCIEDAD CIVIL Y DE LA ACADEMIA, CON VOCACIÓN Y EXPERIENCIA SOBRE LOS DERECHOS HUMANOS Y LA PREVENCIÓN, ATENCIÓN, SANCIÓN Y ERRADICACIÓN DE LA VIOLENCIA DE GÉNERO, SE INTEGREN COMO INVITADOS A LOS TRABAJOS DEL SEPASE, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b> PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORLEOS Y SECRETARIA EJECUTIVA DEL SEPASE. <b> (5 MINS) </b> <a href="http://https://drive.google.com/file/d/1RR273DCWjn7TzJT7mRRusrFaTXWzmY46/view?usp=sharing" target="_blank" rel="noopener noreferrer">Mostrar documento</a></li>
                <li>PRESENTACIÓN Y EN SU CASO APROBACIÓN DEL ACUERDO POR EL QUE SE ADOPTA LA AGEENDA PARA LA PREVENCIÓN ATENCIÓN Y SANCIÓN DE LA VIOLENCIA CONTRA LAS MUJERES Y NIÑAS, EMANADA DE LA ESTRATEGIA NACIONAL DE PAZ Y SEGURIDAD, POR PARTE DE LA <b>ARQUITECTA FLOR DESSIRÉ LEÓN HERNÁNDEZ</b>, PRESIDENTA DEL INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS Y SECRETARIA EJECUTIVA DEL SEPASE <b>(5 MINS)</b> <a href="https://drive.google.com/file/d/1pjqQH1KEvTz2dOqOiRet4MdNy0K-bYWY/view?usp=sharing" target="_blank">- Mostrar documento</a></li>
                <li>ASUNTOS GENERALES 
                    <ol type="A">
                        <li>PRESENTACIÓN SOBRE EL INFORME DE SEGUIMIENTO AL MECNISMO DE ALERTA DE VIOLENCIA DE GÉNERO, RENDIDO POR EL ESTDO DEL PERIODO 2020. POR PARTE DEL <b>LICENCIADO PABLO HÉCTOR OJEDA CÁRDENAS</b> SECRETARIO DE GOBIERNO <a href="https://drive.google.com/file/d/1x9moAgSIt1hoNjbCgeMCC4Iw7YT3Tz5w/view?usp=sharing" target="_blink">INFORME AVG</a><b>(5 MINS)</b></li>


                        <li>PRESENTACIÓN DEL ESTATUS DEL REFUGIO "EL VUELO DE LAS MARIPOSAS", POR PARTE DE LA PSICOLOGA ANA MARÍA GUITIERRES GONZÁLEZ, DE CON DECICSIÓN MUJERES POR MORELOS, A.C.<b>(5 MINS)</b> <a href="#" target="_blank"></a></li>
                    </ol>
                </li>
                <li>CLAUSURA DE LA SESIÓN POR PARTE DEL <b>LICENCIADO PABLO OJEDA CÁRDENAS</b>, SECRETARIO DE GOBIERNO <b>(5 MINS)</b></li>
            </ol>
            <div class="orden-fecha-duracion">
                <h4>FECHA 14 DE JUNIO DE 2021</h4>
                <h4>DURACIÓN DEL EVENTO: 60 MINS</h4>
            </div>
             <div class="regresar-dashboard">
                <button> <router-link to="/dashboard">Regresar</router-link></button>
            </div>
        </div>
       
    </div>
</template>